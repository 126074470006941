import React from 'react';
import './Kunder.css';
import chocbyz from '../assets/chocbyz.png';
import hoforsbgk from '../assets/hoforsbgk.png';
import forgedhub from '../assets/forgedhub.png';
import monkeydev from '../assets/monkeydev.png';
import devblog from '../assets/devblog.png';
import codecraftsman from '../assets/codecraftsman.png';
const Kunder = () => {
  return (
    <div className="kunder">
      <section className="kunder-hero">
        <h1>Våra Kunder</h1>
        <p>Vi är stolta över att samarbeta med en mängd olika företag!</p>
      </section>

      <section className="kunder-list">
        <h2>Utvalda Kunder</h2>
        
        <div className="client-cards">
        <div className="client-card">
            <h3>Choc by Z</h3>
            <img src={chocbyz} alt="Choc by Z" />
          </div>
          <div className="client-card">
            <h3>Hofors BGK</h3>
            <img src={hoforsbgk} alt="Hofors Bangolfklubb" />
          </div>
          <div className="client-card">
            <h3>ForgedHub</h3>
            <img src={forgedhub} alt="ForgedHub" />
          </div>
          <div className="client-card">
            <h3>MonkeyDev</h3>
            <img src={monkeydev} alt="MonkeyDev" />
          </div>
          <div className="client-card">
            <h3>CodeCraftsMan</h3>
            <img src={codecraftsman} alt="CodeCraftMan" />
          </div>
          <div className="client-card">
            <h3>DevBlog</h3>
            <img src={devblog} alt="DevBlog" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Kunder;


