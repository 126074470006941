import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Info from './components/Info';
import Kunder from './components/Kunder';
import Offert from './components/Offert';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/info" element={<Info />} />
          <Route path="/kunder" element={<Kunder />} />
          <Route path="/offert" element={<Offert />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
