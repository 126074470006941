import React, { useState } from 'react';
import './About.css';

const About = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleInfo = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="about">
      <section className="about-hero">
        <h1>Om Codeforged</h1>
        <p>Vårt uppdrag är att forma framtiden för teknologi, rad för rad.</p>
      </section>

      <section className="about-content">
        <h2>Vilka är vi!</h2>
        <p>
          Vi är ett team av passionerade utvecklare, designers och innovatörer, dedikerade till att leverera 
          förstklassiga mjukvarulösningar som hjälper företag att växa och lyckas.
        </p>

        <h2>Våra Värderingar</h2>
        <ul>
          <li onClick={() => toggleInfo(0)} className={activeIndex === 0 ? 'active' : ''}>
            Innovation
            {activeIndex === 0 && (
              <p className="value-text">
                Vi strävar efter att ligga i framkant av teknologin genom att ständigt utforska nya
                idéer och lösningar för att lösa komplexa problem och skapa värde för våra kunder.
              </p>
            )}
          </li>

          <li onClick={() => toggleInfo(1)} className={activeIndex === 1 ? 'active' : ''}>
            Kvalitet
            {activeIndex === 1 && (
              <p className="value-text">
                Vi är engagerade i att leverera högkvalitativa lösningar genom hela utvecklingsprocessen,
                från idé till färdig produkt, för att säkerställa långsiktig framgång för våra kunder.
              </p>
            )}
          </li>

          <li onClick={() => toggleInfo(2)} className={activeIndex === 2 ? 'active' : ''}>
            Integritet
            {activeIndex === 2 && (
              <p className="value-text">
                Vi värderar ärlighet och transparens i allt vi gör, och vi strävar efter att bygga långvariga
                relationer med våra kunder baserade på förtroende och respekt.
              </p>
            )}
          </li>
        </ul>
      </section>
    </div>
  );
};

export default About;

