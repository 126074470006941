import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png'; // Importera logotypen här
import headerBackground from '../assets/bg.png'; // Om du har en bakgrundsbild

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header" style={{ backgroundImage: `url(${headerBackground})` }}> {/* Lägg till en bakgrundsbild här */}
      <div className="logo">
        <img src={logo} alt="Codeforged Logo" className="logo-img" /> {/* Lägg till logotypen här */}
      </div>
      <nav className={`nav-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/" onClick={closeMenu}>Start</Link></li>
          <li><Link to="/about" onClick={closeMenu}>Om oss</Link></li>
          <li><Link to="/kunder" onClick={closeMenu}>Kunder</Link></li>
          <li><Link to="/info" onClick={closeMenu}>Information</Link></li>
          <li><Link to="/offert" onClick={closeMenu}>Offert</Link></li>
        </ul>
      </nav>
      <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </header>
  );
};

export default Header;



