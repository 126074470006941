import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div className="home">
      <section className="hero">
        <div className="hero-content">
          <h1>Välkommen till CodeForged Tech</h1>
          <p>Din partner i att bygga banbrytande mjukvarulösningar.</p>
          <a href="/about" className="cta-btn">Läs Mer</a>
          <a href="/info" className="cta-btn secondary">Kontakta Oss</a>
        </div>
      </section>

      <section className="features">
        <h2>Detta kan vi erbjuda er</h2>
        <div className="feature-cards">
          <div className="feature-card">
            <h3>Skräddarsydd Utveckling</h3>
            <p>Vi levererar skräddarsydda lösningar för att möta dina affärsbehov.</p>
          </div>
          <div className="feature-card">
            <h3>Molnintegration</h3>
            <p>Smidiga molntjänster för att höja din infrastruktur.</p>
          </div>
          <div className="feature-card">
            <h3>Support & Underhåll</h3>
            <p>Var i framkant med våra omfattande supportpaket.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
