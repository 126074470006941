import React, { useState } from 'react';
import './Info.css';

const Info = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleInfo = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="info">
      <section className="info-hero">
        <h1>Information</h1>
        <p>Allt du behöver veta om våra tjänster och hur vi kan hjälpa dig.</p>
      </section>

      <section className="info-details">
        <h2>Vår Process</h2>
        <p>
          Vi använder en strukturerad och agil arbetsmetod för att säkerställa att ditt projekt
          inte bara levereras i tid, utan också håller sig inom den överenskomna budgeten.
          Genom att arbeta i iterativa cykler, så kallade "sprintar", kan vi snabbt anpassa oss
          efter förändrade krav och kontinuerligt förbättra projektets resultat. Detta innebär att du
          som kund har full insyn i utvecklingen, kan ge feedback under projektets gång och alltid vet
          exakt var vi befinner oss i processen. På så sätt säkerställer vi att projektet levererar hög
          kvalitet, effektivt och kostnadseffektivt.
        </p>

        <h2>Nyckelfördelar</h2>
        <ul>
          <li onClick={() => toggleInfo(0)} className={activeIndex === 0 ? 'active' : ''}>
            Skräddarsydda Lösningar
            {activeIndex === 0 && (
              <p className="info-text">
                Vi erbjuder skräddarsydda lösningar som är utformade specifikt för dina behov, oavsett om
                det gäller webb- eller apputveckling, för att säkerställa att vi möter dina mål och krav.
              </p>
            )}
          </li>

          <li onClick={() => toggleInfo(1)} className={activeIndex === 1 ? 'active' : ''}>
            Kontinuerligt Stöd
            {activeIndex === 1 && (
              <p className="info-text">
                Vi tillhandahåller löpande support och underhåll efter att ditt projekt har slutförts för att
                säkerställa att allt fungerar smidigt och enligt plan.
              </p>
            )}
          </li>

          <li onClick={() => toggleInfo(2)} className={activeIndex === 2 ? 'active' : ''}>
            Skalbarhet
            {activeIndex === 2 && (
              <p className="info-text">
                Våra lösningar är byggda med skalbarhet i åtanke, vilket innebär att ditt system enkelt
                kan växa och anpassas när ditt företag expanderar.
              </p>
            )}
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Info;

