import React, { useState } from 'react';
import './Offert.css';

const Offert = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [project, setProject] = useState('');
  const [formStatus, setFormStatus] = useState('');
  const [error, setError] = useState('');

  // En enkel regex för att validera e-postformat
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Validering av e-post
    if (!validateEmail(email)) {
      setError('Vänligen ange en giltig e-postadress.');
      return;
    }

    try {
      const response = await fetch('https://formcarry.com/s/7mCe3o3uF6o', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          email,
          project,
        }),
      });

      const responseData = await response.json();

      if (responseData.code === 200) {
        setFormStatus('Tack för din förfrågan! Vi kommer att kontakta dig snart.');
        setName('');
        setEmail('');
        setProject('');
        setError('');
      } else if (responseData.code === 422) {
        setError(responseData.message); // Valideringsfel från Formcarry
      } else {
        setError(responseData.message); // Övriga fel från Formcarry
      }
    } catch (error) {
      setError(error.message || 'Något gick fel. Vänligen försök igen.');
    }
  };

  return (
    <div className="offert">
      <section className="offert-hero">
        <h1>Begär en Offert</h1>
        <p>Berätta om ditt projekt så återkommer vi med en detaljerad offert.</p>
      </section>

      <section className="offert-form">
        <h2>Få din gratis Offert</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Namn</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Ange ditt namn"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">E-post</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Ange din e-post"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="project">Projekt Detaljer</label>
            <textarea
              id="project"
              name="project"
              placeholder="Beskriv ditt projekt"
              value={project}
              onChange={(e) => setProject(e.target.value)}
              required
            ></textarea>
          </div>

          <button type="submit" className="submit-btn">Skicka</button>
        </form>

        {formStatus && <p className="form-status">{formStatus}</p>}
        {error && <p className="error">{error}</p>}
      </section>
    </div>
  );
};

export default Offert;




